class Script {

	constructor() {
		$(document).ready(() => {
			this.$element = $('body');
			this.map = false;
			this.init();
		});
	}

	init() {
		this.$element.find('.js-y-maps').each((e, el) => {
			this.ymapsInit();
		});

		this.$element.find('.js-select').select2({
			minimumResultsForSearch: Infinity,
			width: 'auto',
			dropdownAutoWidth: true,
		});

		this.$element.find('.js-select-city').each((e, el) => {
			this.initSelectsCity(el);
		});

		this.$element.find('.js-sidebar-title').on('click', function(e) {
			if ($(window).innerWidth() <= 768) {
				const $el = $(e.target);

				$el.siblings('.js-sidebar-inner').slideToggle({
					complete: () => {
						$el.toggleClass('open');
					},
				});
			}
		});

		this.$element.find('.js-slider').each((e, el) => {
			this.initSlider(el);
		});

		this.$element.find('.js-slider-success').each((e, el) => {
			this.initSliderSucess(el);
		});

		this.$element.find('.js-slider-year').each((e, el) => {
			this.initSliderYear(el);
		});

		this.$element.find('.js-tabs').each((e, el) => {
			this.tabs(el);
		});

		this.$element.find('.js-form').each((e, el) => {
			this.initForm(el);
		});

		this.cityToggle();
		this.menuToggle();
		this.initMobileVersion();
		this.initModals();
		this.initFormNewCity();

		this.$element.find('.js-slider-video').each((e, el) => {
			this.initSliderVideo(el);
		});

		this.$element.find('.js-article-slider').each((e, el) => {
			this.initSliderArticle(el);
		});

		this.$element.find('.js-footer').each((e, el) => {
			this.initFooter(el);
		});

		this.$element.find('.js-location').each((e, el) => {
			this.initLocationDropdown(el);
		});

		this.$element.find('.js-accordion').each((e, el) => {
			this.initJobAccordion(el);
		});

		this.$element.find('.js-video-player').each((e, el) => {
			this.initVideo(el);
		});
	}

	initVideo(el) {
		const $el = $(el);
		const $playButton = $el.find('.js-play-control');
		const $stopButton = $el.find('.js-stop-control');
		const $video = $el.find('.js-video');

		$el.find('.js-play-control').on('click', (e) => {
			const $button = $(e.target);
			$video[0].play();

			$playButton.fadeOut('slow', () => {
				$stopButton.fadeIn();
			});
		});

		$el.find('.js-stop-control').on('click', (e) => {
			const $button = $(e.target);
			$video[0].pause();
			$video[0].currentTime = 0;

			$stopButton.fadeOut('slow', () => {
				$playButton.fadeIn();
			});
		});

		$video.on('ended', function() {
			$stopButton.fadeOut('slow', () => {
				$playButton.fadeIn();
			});
		});
	}

	initModals() {
		$('.modal').iziModal();
	};

	initSelectsCity(el) {
		const $el = $(el);
		$el.select2({
			minimumResultsForSearch: Infinity,
			width: 'resolve',
		});

		$el.on('change', e => {
			$.ajax({
				type: 'POST',
				data: {
					CITY_ID: $(e.target).find(':selected').attr('value'),
				},
				url: '/local/ajax/form/formHandler.php',
				dataType: 'json',
				async: true,
				success: (data) => {
					const $form = $el.closest('.js-form');
					const $selectElements = $form.find('.js-select-jobs');
					$selectElements.find('option').remove();
					$selectElements.append('<option></option>'); //пустой элемент для
					                                             // плейсхолдера

					if (data['LIST_JOBS'].length === 0) {
						$selectElements.closest('.js-input').slideUp();
					}
					else {
						$selectElements.closest('.js-input').slideDown();
						data['LIST_JOBS'].forEach(element => {
							$selectElements.append('<option value="' + element['ID'] + '">' +
									element['NAME'] + '</option>');
						});
					}

					const $reviewPlaces = $form.find('.js-review-place');
					$reviewPlaces.slideUp('fast', () => {
						$reviewPlaces.find('label').remove();
						data['LIST_REVIEW_PLACES'].forEach((element, i) => {
							if (i == 0) {
								$reviewPlaces.append('<label class="input-field__radio js-form-city">\n' +
										'<input type="radio" class="input-field__radio" name="address" data-name="' +
										element['PROPERTY_UF_ADDRESS_VALUE'] + '" data-address="' +
										element['PROPERTY_UF_ADDRESS_VALUE'] +
										'"  data-coordinates="' + element['PROPERTY_UF_MAP_VALUE'] +
										'" value="' + element['ID'] + '" checked>\n' +
										'<span class="input-field__radio-text">' + element['NAME'] +
										', ' + element['PROPERTY_UF_ADDRESS_VALUE'] + ' </span>\n' +
										'</label>');
							}
							else {
								$reviewPlaces.append('<label class="input-field__radio js-form-city">\n' +
										'<input type="radio" class="input-field__radio" name="address" data-name="' +
										element['PROPERTY_UF_ADDRESS_VALUE'] + '" data-address="' +
										element['PROPERTY_UF_ADDRESS_VALUE'] +
										'"  data-coordinates="' + element['PROPERTY_UF_MAP_VALUE'] +
										'" value="' + element['ID'] + '">\n' +
										'<span class="input-field__radio-text">' + element['NAME'] +
										', ' + element['PROPERTY_UF_ADDRESS_VALUE'] + ' </span>\n' +
										'</label>');
							}
						});
						this.initFormNewCity();
					}).slideDown();
				},
			});
		});
	}

	ymapsInit() {
		$('.js-form-city').each((i, el) => {
			const $el = $(el);
			const $input = $el.find('input:radio:checked');

			if ($input.length > 0) {
				ymaps.ready(() => {
					const coordinates = $input.data('coordinates').split(',');

					this.map = new ymaps.Map('map', {
						center: coordinates,
						zoom: 15,
						controls: [],
						type: 'yandex#hybrid',
					});

					let myPlacemark1 = new ymaps.Placemark(coordinates, {
						balloonContentHeader: $input.data('name'),
						balloonContentBody: $input.data('address'),
					}, {});

					this.map.geoObjects.removeAll();
					this.map.geoObjects.add(myPlacemark1);
				});
			}
		});
	}

	initFormNewCity() {
		$('.js-review-place').each((i, el) => {
			const $el = $(el);
			const $input = $el.first().find('input').first();

			console.log($input);

			if ($input.length > 0) {
				ymaps.ready(() => {
					const coordinates = $input.data('coordinates').split(',');

					let myPlacemark1 = new ymaps.Placemark(coordinates, {
						balloonContentHeader: $el.data('name'),
						balloonContentBody: $el.data('address'),
					}, {});

					this.map.geoObjects.removeAll();
					this.map.geoObjects.add(myPlacemark1);

					this.map.setCenter(coordinates, 15, {
						duration: 500,
						timingFunction: 'ease-in-out',
					});
				});
			}
		});

		$('.js-form-city').on('click', (e) => {
			const $el = $(e.target).siblings('input');

			if ($el.length > 0) {
				const coordinates = $el.data('coordinates').split(',');

				let myPlacemark1 = new ymaps.Placemark(coordinates, {
					balloonContentHeader: $el.data('name'),
					balloonContentBody: $el.data('address'),
				}, {});

				this.map.geoObjects.removeAll();
				this.map.geoObjects.add(myPlacemark1);

				this.map.setCenter(coordinates, 15, {
					duration: 500,
					timingFunction: 'ease-in-out',
				});
			}
		});
	}

	initJobAccordion(el) {
		const $el = $(el);
		const $links = $el.find('.js-accordion-link');
		const $lists = $el.find('.js-accordion-list');

		$links.on('click', (e) => {
			$lists.hide('drop');
			const $currentEl = $(e.target);

			if (!$currentEl.hasClass('active')) {
				$links.removeClass('active');
				$currentEl.siblings('.js-accordion-list').toggle('drop');
				$currentEl.addClass('active');
			}
			else {
				$currentEl.removeClass('active');
			}
		});
	}

	getCookie(name) {
		var matches = document.cookie.match(new RegExp(
				'(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
				'=([^;]*)',
		));
		return matches ? decodeURIComponent(matches[1]) : false;
	}

	initLocationDropdown(el) {
		const $el = $(el);
		const $link = $el.find('.js-location-link');
		const $dropdown = $el.find('.js-location-list');

		$link.on('click', (e) => {
			$link.addClass('active');
			$dropdown.slideDown();
		});

		$(window).on('click', (e) => {
			if ($(e.target).closest($dropdown).length === 0 &&
					$(e.target).closest($link).length === 0 &&
					$(e.target).closest('#fade').length === 0) {
				$dropdown.slideUp();
				$link.removeClass('active');
			}
		});
	}

	initMobileVersion() {
		const $mobileLink = this.$element.find('.js-mobile-version');
		const $desktopLink = this.$element.find('.js-desktop-version');

		const activeMobile = (visual = false) => {
			$mobileLink.hide().addClass('unactive');
			$desktopLink.show().removeClass('unactive');

			if (!visual) {
				document.cookie = 'mobileVersion=1;';
				$('meta[name="viewport"]').
						attr('content',
								'width=device-width, initial-scale=1, maximum-scale=1');
			}
		};

		const activeDesktop = (visual = false) => {
			$desktopLink.hide().addClass('unactive');
			$mobileLink.show().removeClass('unactive');

			if (!visual) {
				document.cookie = 'mobileVersion=0;';
				$('meta[name="viewport"]').
						attr('content', 'width=1200, user-scalable=yes');
			}
		};

		$mobileLink.on('click', (e) => {
			activeMobile();
		});

		$desktopLink.on('click', (e) => {
			activeDesktop();
		});

		if ($(window).outerWidth() > 1185) {
			activeDesktop(true);
		}
		else {
			if (this.getCookie('mobileVersion') == false) {
				activeMobile();
			}

			activeMobile(true);
		}

		$(window).on('resize', () => {
			if (this.getCookie('mobileVersion') == false) {
				if (window.screen.width < 1185) {
					activeDesktop(true);
				}
				else {
					activeMobile(true);
				}
			}
		});

		if (this.getCookie('mobileVersion') == 1) {
			activeMobile();
		}

		if (this.getCookie('mobileVersion') == 0) {
			activeDesktop();
		}

	}

	initFooter(el) {
		const $el = $(el);
		const $menuToggleLink = $el.find('.js-footer-menu-link');
		const $footersMenus = $el.find('.js-footer-menu');

		$menuToggleLink.on('click', (e) => {
			const $currEl = $(e.target);
			const opened = $currEl.hasClass('active');
			$footersMenus.find('.js-footer-menu-body').hide('drop');
			$menuToggleLink.removeClass('active');

			if (!opened) {
				$currEl.siblings('.js-footer-menu-body').toggle('drop');
				$currEl.toggleClass('active');
			}
		});
	}

	initSlider(el) {
		const $el = $(el);

		const prevArrow = $el.find('.js-slider-prev');
		const nextArrow = $el.find('.js-slider-next');

		$el.slick({
			prevArrow: prevArrow,
			nextArrow: nextArrow,
			slide: '.js-slide',
			autoplay: true,
			autoplaySpeed: 2000,
			infinite: true,
		});
	}

	initSliderSucess(el) {
		const $el = $(el);

		const prevArrow = $el.find('.js-slider-prev');
		const nextArrow = $el.find('.js-slider-next');

		$el.slick({
			prevArrow: prevArrow,
			nextArrow: nextArrow,
			slide: '.js-slide',
			autoplay: true,
			autoplaySpeed: 2000,
			variableWidth: false,
			infinite: true,
			responsive: [
				{
					breakpoint: 1190,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: false,
					},
				},
				{
					breakpoint: 550,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: false,
					},
				},
			],
		});
	}

	initSliderYear(el) {
		const $el = $(el);

		const prevArrow = $el.find('.js-slider-prev');
		const nextArrow = $el.find('.js-slider-next');

		$el.slick({
			prevArrow: prevArrow,
			nextArrow: nextArrow,
			slide: '.js-slide',
			autoplay: true,
			autoplaySpeed: 2000,
			infinite: true,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: false,
					},
				},
			],
		});
	}

	initSliderVideo(el) {
		const $el = $(el);

		$el.slick({
			dots: true,
			dotsClass: 'slider__dots-video',
			slide: '.js-slide',
			autoplay: true,
			autoplaySpeed: 2000,
			infinite: true,
		});
	}

	initSliderArticle(el) {
		const $el = $(el);
		const prevArrow = $el.find('.js-slider-prev');
		const nextArrow = $el.find('.js-slider-next');
		const slideNumber = $el.find('.js-slider-number');
		const slideQuantity = $el.find('.js-slider-quantity');
		const slideesCount = $el.find('.js-article-slide').length;

		slideQuantity.text(slideesCount);
		slickSliderInit();

		//Смена номера текущего слайда
		$el.on('init reInit afterChange',
				function(event, slick, currentSlide, nextSlide) {
					let i = (currentSlide ? currentSlide : 0) + 1;
					slideNumber.text(i);
				});

		//Слайдер прячется в настольной версии
		$(window).on('resize', function() {
			slickSliderInit();
		});

		function slickSliderInit() {
			if ($(window).outerWidth() < 1185 && !$el.hasClass('slick-initialized')) {
				$el.slick({
					slide: '.js-article-slide',
					slidesToShow: 1,
					prevArrow: prevArrow,
					nextArrow: nextArrow,
					autoplay: true,
					autoplaySpeed: 2000,
					arrows: true,
					infinite: true,
					responsive: [
						{
							breakpoint: 10000,
							settings: 'unslick',

						},
						{
							breakpoint: 1170,
						},
					],
				});
			}
		}

	}

	quantity(el) {
		const $el = $(el);
		const $quantityAdd = $el.find('.js-quantity-add');
		const $quantityDelete = $el.find('.js-quantity-delete');
		const $quantityNumber = $el.find('.js-quantity-number');
		let quantity = parseInt($quantityNumber.text());

		$quantityDelete.on('click', () => setQuantity(quantity - 1));
		$quantityAdd.on('click', () => setQuantity(quantity + 1));

		function setQuantity(number) {
			quantity = (number === 0) ? 1 : number;
			$quantityNumber.text(quantity);
		}
	}

	tabs(el) {
		const $el = $(el);
		const $tabsContent = $el.find('.js-tabs-content');
		this.initMobileTabs = false;

		//Функция для клонирования вкладок и показа их в мобильной версии
		const setActiveMobileTab = (start = false, rebuild = false) => {
			if (start) {
				$el.find('.js-tabs-link.active').removeClass('active');

			}
			else {
				if (rebuild) {
					this.$currentTab = $el.find('.js-tabs-link').first();
				}

				const $clonedEl = $el.find('.cloned');
				$clonedEl.slideUp('normal', 'linear', () => $clonedEl.remove());
				const $currentTabContent = $(
						$tabsContent[this.$currentTab.index('.js-tabs-link')]).clone();
				this.$currentTab.siblings().removeClass('active');
				this.$currentTab.addClass('active');
				this.$currentTab.after($currentTabContent);
				$currentTabContent.slideDown('normal', 'linear',
						() => $currentTabContent.addClass('active').addClass('cloned'));
				this.initMobileTabs = true;
			}
		};

		const setActiveDesktopTab = (start = false) => {
			if (start) {
				this.$currentTab = $el.find('.js-tabs-link').first();
			}

			const $currentTabContent = $($tabsContent[this.$currentTab.index()]);
			this.$currentTab.siblings().removeClass('active');
			this.$currentTab.addClass('active');
			$tabsContent.removeClass('active');
			$tabsContent.hide();
			$currentTabContent.fadeIn('fast', 'linear',
					() => $currentTabContent.addClass('active'));
		};

		//Перестраиваем табы для мобильной версии (если открыта мобильная версия)
		if ($(window).outerWidth() <= 1185 &&
				this.getCookie('mobileVersion') != 0) {
			setActiveMobileTab(true);
		}
		else {
			setActiveDesktopTab(true);
		}

		//Перестраиваем табы для мобильной версии (в случае ресайза до мобильной
		// версии)
		$(window).on('resize', () => {
			if ($(window).outerWidth() > 1185 && this.initMobileTabs) {
				const $clonedEl = $el.find('.cloned');
				$clonedEl.remove();
				setActiveDesktopTab(true);
				this.initMobileTabs = false;
			}

			if ($(window).outerWidth() <= 1185 && this.initMobileTabs) {
				setActiveMobileTab(true);
			}
		});

		//При нажатии на вкладку выводим нужную
		$el.find('.js-tabs-link').on('click', e => {
			this.$currentTab = $(e.target);
			if (!this.$currentTab.hasClass('active')) {
				if ($(window).outerWidth() > 1185) {
					setActiveDesktopTab();
				}
				else {
					setActiveMobileTab();
				}
			}

		});

	}

	menuToggle() {
		const $header = this.$element.find('.js-header');
		const $hamburger = $header.find('.js-menu-toggle');
		const $menu = $header.find('.js-mobile-menu');
		const $bgLayer = this.$element.find('.bg-layer');
		const $cityMenu = $header.find('.js-mobile-city-menu');
		const $menuList = $header.find('.js-mobile-menu-list');

		$hamburger.on('click', e => {
			$menuList.fadeIn();
			$cityMenu.fadeOut();
			$bgLayer.fadeToggle();
			$menu.toggle('slide');
		});
	}

	cityToggle() {
		const $header = this.$element.find('.js-header');
		const $cityMenu = $header.find('.js-mobile-city-menu');
		const $link = $header.find('.js-mobile-city-link');
		const $menu = $header.find('.js-mobile-menu-list');
		const $linkChoose = $('.js-city-choose-link');

		$link.on('click', e => {
			$menu.toggle('slide');
			$cityMenu.toggle('slide');
		});

		$linkChoose.on('click', e => {
			const id = $(e.target).data('id');
			BX.setCookie('BITRIX_SM_CITY', id, {expires: 86400, path: '/'});
			document.location.reload();
		});

		$(document).on('click', '.js-location-select .js-city-choose-link', e => {
			const id = $(e.target).data('id');
			BX.setCookie('BITRIX_SM_CITY', id, {expires: 86400, path: '/'});
			document.location.reload();
		});

		// if(!this.getCookie('BITRIX_SM_CITY')) {
		//     const modal = $('#city-choose');
		//     modal.iziModal();
		//     modal.iziModal('open');
		// }

		if (!this.getCookie('BITRIX_SM_CITY')) {
			$('#fade').fadeIn();
			$('.js-location-link').addClass('active');
			$('.js-location-list').slideDown();
			$('.js-mobile-start-city').addClass('active');
			$('.js-mobile-start-city .js-mobile-city-menu').slideDown();
		}
	}

	initForm(el) {
		const $form = $(el);

		$('.js-file-add').on('click', () => {
			$form.find('.js-file-input').trigger('click');
		});

		$form.find('.js-file-input').on('change', (e) => {
			if (!!e.target.files[0]) {
				$('.js-resume-block-file-name').
						html('Вы загрузили файл : <br>' + e.target.files[0].name);
				$('.js-resume-block-add').fadeOut('fast', () => {
					$('.js-resume-block-reset').css('display', 'flex');
				});
			}
		});

		$('.js-file-reset').on('click', () => {
			$form.find('.js-file-input').value = '';
			$('.js-resume-block-reset').fadeOut('fast', () => {
				$('.js-resume-block-add').fadeIn();
			});
		});

		$form.find('.js-phone').mask('+7 (000) 000-00-00');
		$form.find('.js-date').mask('00.00.0000');

		$form.find('.js-date').datepicker({
			language: 'ru-RU',
			autoPick: true,
		});

		$.validator.addMethod('checkPhone', function(value, element) {
			return /\+\d{1} \(\d{3}\) \d{3}-\d{2}-\d{2}/g.test(value);
		});

		$.extend($.validator.messages, {
			checkPhone: 'Введите правильный номер телефона.',
			required: 'Это поле необходимо заполнить.',
			remote: 'Пожалуйста, введите правильное значение.',
			email: 'Пожалуйста, введите корректный email.',
			url: 'Пожалуйста, введите корректный URL.',
			date: 'Пожалуйста, введите корректную дату.',
			dateISO: 'Пожалуйста, введите корректную дату в формате ISO.',
			number: 'Пожалуйста, введите число.',
			digits: 'Пожалуйста, вводите только цифры.',
			creditcard: 'Пожалуйста, введите правильный номер кредитной карты.',
			equalTo: 'Пожалуйста, введите такое же значение ещё раз.',
			extension: 'Пожалуйста, выберите файл с расширением jpeg, pdf, doc, docx.',
			maxlength: $.validator.format(
					'Пожалуйста, введите не больше {0} символов.'),
			minlength: $.validator.format(
					'Пожалуйста, введите не меньше {0} символов.'),
			rangelength: $.validator.format(
					'Пожалуйста, введите значение длиной от {0} до {1} символов.'),
			range: $.validator.format('Пожалуйста, введите число от {0} до {1}.'),
			max: $.validator.format(
					'Пожалуйста, введите число, меньшее или равное {0}.'),
			min: $.validator.format(
					'Пожалуйста, введите число, большее или равное {0}.'),
			maxsize: 'Максимальный размер файла - 5мб',
		});

		$form.validate({
			errorPlacement: function(error, element) {
				if (element.attr('name') != 'nda' && element.attr('name') != 'file') {
					error.insertAfter(element);
					$('<div class=\'error-icon\'></div>').insertAfter(element);
				}

				if (element.attr('name') === 'file' && error.text() != '') {
					const modal = $('#form-error-file');
					modal.find('.js-form-error').text(error.text());
					modal.iziModal('open');
				}

				return true;
			},
			success: function(element) {
				if (element.attr('name') != 'nda') {
					$(element).siblings('.error-icon').remove();
				}

				return true;
			},
			lang: 'ru',
			rules: {
				name: {
					required: true,
					minlength: 2,
				},
				email: {
					minlength: 2,
					email: true,
				},
				tel: {
					required: true,
					checkPhone: true,
				},
				nda: {
					required: true,
				},
				date: {
					required: true,
				},
				time: {
					required: true,
				},
				year: {
					required: true,
				},
				file: {
					extension: 'png|jpg|jpeg|pdf|doc|docx',
					maxsize: 5000000,
				},
			},
			submitHandler: function(form) {
				const $form = $(form);
				let formData = $form.serializeArray();

				const fileData = $form.find('.js-file-input').prop('files')[0];

				formData = new FormData(form);

				$.ajax({
					url: form.action,
					type: 'post',
					data: formData,
					contentType: false,
					processData: false,
					dataType: 'text',
					cache: false,
					success: function(response) {
						$form.trigger('reset');
						const modal = $('#form-success');
						modal.iziModal('open');
					},
				});
			},
		});
	}
}

const script = new Script();
